import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Environment
import { environment } from '../../../../environments/environment';

// Types
import {
  GetCompaniesParams,
  GetCompaniesListRespone,
  GetCompanyResponse,
  DeleteCompanyResponse,
  SetCompanyParams,
  SetCompanyResponse,
} from '../../../shared/types/company';

@Injectable()
export class CompaniesService {
  constructor(private http: HttpClient) {}

  getCompanies(params: GetCompaniesParams): Observable<GetCompaniesListRespone> {
    const url = environment.url + '/api/GetCompanies';
    return this.http.post<GetCompaniesListRespone>(url, params);
  }

  getCompany(idcompany: number): Observable<GetCompanyResponse> {
    const url = environment.url + '/api/GetCompany';
    return this.http.post<GetCompanyResponse>(url, { Idcompany: idcompany });
  }

  deleteCompany(idcompany: number): Observable<DeleteCompanyResponse> {
    const url = environment.url + '/api/DeleteCompany';
    return this.http.delete<DeleteCompanyResponse>(url, { body: { Idcompany: idcompany } });
  }

  setCompany(params: SetCompanyParams): Observable<SetCompanyResponse> {
    const url = environment.url + '/api/SetCompany';
    return this.http.post<SetCompanyResponse>(url, params);
  }
}
