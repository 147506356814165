import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeItIT from '@angular/common/locales/it';
import localeEnGB from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpBackend, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// eCharts
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import langIT from 'echarts/lib/i18n/langIT';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import langEN from 'echarts/lib/i18n/langEN';

// i18n
import { createTranslateLoader, TranslationService } from './core/services/translation.service';

// Interceptors
import httpHeaders from './core/interceptor/http-headers.interceptor';
import errorHandler from './core/interceptor/error-handler.interceptor';

// Guards
import { checkRoles, isAuthenticatedGuard, isNotAuthenticatedGuard } from './core/guards/user.guard';

// Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// Enums
import { Roles } from './shared/enums/roles';

// Services
import { StorageService } from './core/services/storage.service';
import { AuthenticationService } from './core/authentication/authentication.service';

import { AppComponent } from './app.component';

registerLocaleData(localeItIT, 'it-IT');
registerLocaleData(localeEnGB, 'en-GB');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
echarts.registerLocale('it-IT', langIT as unknown);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
echarts.registerLocale('en-GB', langEN as unknown);

const routes: Routes = [
  {
    path: 'sign',
    loadChildren: () => import('./modules/sign/sign.module').then((m) => m.SignModule),
    canActivateChild: [isNotAuthenticatedGuard],
  },
  {
    path: 'order-creation',
    loadChildren: () => import('./modules/order-creation/order-creation.module').then((m) => m.OrderCreationModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.PharmacyUser, Roles.PharmacyAdmin)],
  },
  {
    path: 'orders-list',
    loadChildren: () => import('./modules/orders-list/orders-list.module').then((m) => m.OrdersListModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.PharmacyUser, Roles.PharmacyAdmin, Roles.SuperAdmin)],
  },
  {
    path: 'upload-deals',
    loadChildren: () => import('./modules/upload-deals/upload-deals.module').then((m) => m.UploadDealsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin)],
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.SuperAdmin)],
  },
  {
    path: 'companies',
    loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin, Roles.PharmacyAdmin, Roles.PharmacyUser, Roles.SuperAdmin)],
  },
  {
    path: 'check-availabilities',
    loadChildren: () => import('./modules/check-availabilities/check-availabilities.module').then((m) => m.CheckAvailabilitiesModule),
    canActivateChild: [isAuthenticatedGuard],
  },
  {
    path: 'predictions',
    loadChildren: () => import('./modules/predictions/predictions.module').then((m) => m.PredictionsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.Hospital)],
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin)],
  },
  {
    path: '',
    redirectTo: '/orders-list',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/orders-list',
  },
];

function initializeAppFactory(
  storage: StorageService,
  translation: TranslationService,
  authService: AuthenticationService
): () => Promise<void> {
  return () =>
    new Promise<void>((resolve) => {
      // const currentLanguage = storage.getFromStorage<Language>(StorageKey.CurrentLanguage);
      // translation.setDefaultLanguage(currentLanguage ?? 'en-GB');
      translation.setDefaultLanguage('it-IT');

      authService.recoverSession();
      resolve();
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    NgxEchartsModule.forRoot({ echarts }),
    CoreModule,
    SharedModule,
  ],
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([httpHeaders, errorHandler])),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [StorageService, TranslationService, AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
