import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Types
import {
  GetAiProductsClassificationsResponse,
  GetAiProductsTypesResponse,
  GetDemandPredictionsParams,
  GetDemandPredictionsResponse,
  GetEmergencyOrdersHistoryParams,
  GetEmergencyOrdersHistoryResponse,
  GetHistoricalDemandParams,
  GetHistoricalDemandResponse,
  GetKpiParams,
  GetKpiResponse,
  GetMetricsComparisonResponse,
  GetStockoutHistoryParams,
  GetStockoutHistoryResponse,
  GetTopEmergencyOrdersClassificationsParams,
  GetTopEmergencyOrdersClassificationsResponse,
  GetTopEmergencyOrdersProductsParams,
  GetTopEmergencyOrdersProductsResponse,
  GetTopStockoutClassificationsParams,
  GetTopStockoutClassificationsResponse,
  GetTopStockoutProductsParams,
  GetTopStockoutProductsResponse,
} from '../../../shared/types/statistics';

// Environment
import { environment } from '../../../../environments/environment';
@Injectable()
export class StatisticsService {
  constructor(private http: HttpClient) {}

  getDemandPredictions(params: GetDemandPredictionsParams): Observable<GetDemandPredictionsResponse> {
    const url = environment.url + '/api/GetDemandPredictions';
    return this.http.post<GetDemandPredictionsResponse>(url, params);
  }

  getAiProductsTypes(): Observable<GetAiProductsTypesResponse> {
    const url = environment.url + '/api/GetAiProductsTypes';
    return this.http.post<GetAiProductsTypesResponse>(url, {});
  }

  getAiProductsClassifications(): Observable<GetAiProductsClassificationsResponse> {
    const url = environment.url + '/api/GetAiProductsClassifications';
    return this.http.post<GetAiProductsClassificationsResponse>(url, {});
  }

  getKpi(params: GetKpiParams): Observable<GetKpiResponse> {
    const url = environment.url + '/api/GetKpi';
    return this.http.post<GetKpiResponse>(url, params);
  }

  getHistoricalDemand(params: GetHistoricalDemandParams): Observable<GetHistoricalDemandResponse> {
    const url = environment.url + '/api/GetHistoricalDemand';
    return this.http.post<GetHistoricalDemandResponse>(url, params);
  }

  getTopStockoutProducts(params: GetTopStockoutProductsParams): Observable<GetTopStockoutProductsResponse> {
    const url = environment.url + '/api/GetTopStockoutProducts';
    return this.http.post<GetTopStockoutProductsResponse>(url, params);
  }

  getTopStockoutClassifications(params: GetTopStockoutClassificationsParams): Observable<GetTopStockoutClassificationsResponse> {
    const url = environment.url + '/api/GetTopStockoutClassifications';
    return this.http.post<GetTopStockoutClassificationsResponse>(url, params);
  }

  getStockoutHistory(params: GetStockoutHistoryParams): Observable<GetStockoutHistoryResponse> {
    const url = environment.url + '/api/GetStockoutHistory';
    return this.http.post<GetStockoutHistoryResponse>(url, params);
  }

  getTopEmergencyOrdersProducts(params: GetTopEmergencyOrdersProductsParams): Observable<GetTopEmergencyOrdersProductsResponse> {
    const url = environment.url + '/api/GetTopEmergencyOrdersProducts';
    return this.http.post<GetTopEmergencyOrdersProductsResponse>(url, params);
  }

  getTopEmergencyOrdersClassifications(
    params: GetTopEmergencyOrdersClassificationsParams
  ): Observable<GetTopEmergencyOrdersClassificationsResponse> {
    const url = environment.url + '/api/GetTopEmergencyOrdersClassifications';
    return this.http.post<GetTopEmergencyOrdersClassificationsResponse>(url, params);
  }

  getEmergencyOrdersHistory(params: GetEmergencyOrdersHistoryParams): Observable<GetEmergencyOrdersHistoryResponse> {
    const url = environment.url + '/api/GetEmergencyOrdersHistory';
    return this.http.post<GetEmergencyOrdersHistoryResponse>(url, params);
  }

  getMetricsComparison(): Observable<GetMetricsComparisonResponse> {
    const url = environment.url + '/api/GetMetricsComparison';
    return this.http.post<GetMetricsComparisonResponse>(url, {});
  }
}
