import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe as AngularCurrencyPipe } from '@angular/common';
import { TranslationService } from 'src/app/core/services/translation.service';

@Pipe({
  name: 'appCurrency',
})
export class CurrencyPipe extends AngularCurrencyPipe implements PipeTransform {
  constructor(private translate: TranslationService) {
    super(translate.currentLanguage.getValue());
  }

  override transform(
    value: string | number,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string;
  override transform(value: null, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): null;
  override transform(
    value: string | number,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string;
  override transform(value: unknown, currencyCode?: unknown, display?: unknown, digitsInfo?: unknown): string {
    if (isNaN(value as number)) {
      return null;
    }
    const language = this.translate.currentLanguage.getValue();
    const { currency, numberLocale } = this.translate.languages.find((l) => l.localeId === language);
    return super.transform(value as string | number, currency, display as string | boolean, digitsInfo as string, numberLocale);
  }
}
