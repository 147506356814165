import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// PrimeNG
import { ConfirmationService, MessageService } from 'primeng/api';

// Enums
import { Roles } from '../../shared/enums/roles';

// Services
import { ApiService } from '../http/api.service';
import { TranslationService } from '../services/translation.service';

// Types
import { AuthenticationService } from '../authentication/authentication.service';

type Pages = {
  name: string;
  path: string;
  roles?: Roles[];
}[];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() orderStatus: string;

  protected companyName: string = '';

  private readonly pages: Pages = [
    { name: 'HEADER.NEW_ORDER', path: '/order-creation', roles: [Roles.PharmacyUser, Roles.PharmacyAdmin] },
    {
      name: this.isSuperAdmin ? 'HEADER.ORDERS_LIST' : 'HEADER.ORDERS_HISTORY',
      path: '/orders-list',
      roles: [Roles.PharmacyUser, Roles.PharmacyAdmin, Roles.SuperAdmin],
    },
    { name: 'HEADER.UPLOADING_PRICING', path: '/upload-deals', roles: [Roles.IndustryAdmin] },
    {
      name: this.isSuperAdmin ? 'HEADER.COMPANIES_VIEW' : 'HEADER.ASSOCIATED_PHARMACIES_VIEW',
      path: '/companies',
      roles: [Roles.PharmacyAdmin, Roles.IndustryAdmin, Roles.SuperAdmin],
    },
    // { name: 'HEADER.QUICK_EDIT_PRICING', path: '/products', roles: [Roles.IndustryAdmin] },
    // { name: 'HEADER.PRODUCT_MONITOR', path: '/check-availabilities', roles: [Roles.PharmacyUser] },
    { name: 'HEADER.USERS_VIEW', path: '/users', roles: [Roles.SuperAdmin] },
    { name: 'HEADER.PREDICTIONS', path: '/predictions', roles: [Roles.Hospital] },
    { name: 'HEADER.PRODUCTS_LIST', path: '/predictions/products', roles: [Roles.Hospital] },
    { name: 'HEADER.CONTROL_TOWER', path: '/predictions/control-tower', roles: [Roles.Hospital] },
  ];

  constructor(
    private confirmationService: ConfirmationService,
    private translationService: TranslationService,
    private authService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private messageService: MessageService
  ) {}

  protected get availablePages(): Pages {
    const roles = this.authService.user.getValue()?.masterData.roles;

    if (!roles) {
      return [];
    } else {
      return this.pages.filter((page) => {
        if (page.roles) {
          const roleFound = page.roles?.find((role) => roles.includes(role));
          return !!roleFound;
        } else {
          return true;
        }
      });
    }
  }

  protected get userFullName(): string {
    const user = this.authService.user.getValue()?.masterData;
    return `${user?.name} ${user?.surname}`;
  }

  protected get userFirstLetters(): string {
    const user = this.authService.user.getValue()?.masterData;
    return user?.name[0] + user?.surname[0];
  }

  protected get isSuperAdmin(): boolean {
    const user = this.authService.user.getValue()?.masterData;
    return user?.roles.includes(Roles.SuperAdmin);
  }

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user) => {
        if (!this.isSuperAdmin && !user?.masterData.roles.includes(Roles.Hospital)) {
          this.apiService.companies.getCompany(user?.masterData.idcompany).subscribe({
            next: ({ data }) => {
              this.companyName = data.name;
            },
            error: ({ error }: HttpErrorResponse) => {
              this.messageService.add({
                summary: this.translationService.getTranslation('COMMON.ERROR'),
                detail: error as string,
                severity: 'error',
                life: 2000,
              });
            },
          });
        }
      },
    });
  }

  protected logout(): void {
    this.confirmationService.confirm({
      header: this.translationService.getTranslation('LOGOUT.TITLE'),
      message: this.translationService.getTranslation('LOGOUT.CONTENT'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translationService.getTranslation('COMMON.CONFIRM'),
      rejectLabel: this.translationService.getTranslation('COMMON.CANCEL'),
      accept: () => {
        this.authService.logout();
        void this.router.navigateByUrl('/sign');
      },
    });
  }
}
