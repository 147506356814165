import { Injectable } from '@angular/core';
import { HttpBackend } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { BehaviorSubject } from 'rxjs';
import { PrimeNGConfig, Translation } from 'primeng/api';

// Enums
import { StorageKey } from '../../shared/enums/storage';

// Types
import { Language, Languages } from '../../shared/types/translations';

// Services
import { StorageService } from './storage.service';

function buildPath(pathFolder: string): string {
  const BASE_PATH = '/assets/i18n';
  return [BASE_PATH, pathFolder].join('/') + '/';
}

export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  const DIRs = [
    'COMMON',
    'ERRORS',
    'PRIMENG',
    'CHECK_AVAILABILITIES',
    'SIGN',
    'HEADER',
    'ORDER_CREATION',
    'ORDERS_LIST',
    'UPLOAD_DEALS',
    'USERS',
    'COMPANIES',
    'PRODUCTS',
    'PREDICTIONS',
    'FOOTER',
  ];
  const SUFFIX = '.json';

  return new MultiTranslateHttpLoader(
    http,
    DIRs.map((dir) => ({ prefix: buildPath(dir), suffix: SUFFIX }))
  );
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  currentLanguage: BehaviorSubject<Language> = new BehaviorSubject<Language>(null);
  readonly languages: Languages = [
    { label: 'English', localeId: 'en-GB', dateFormat: 'dd/MM/yyyy', currency: 'EUR', numberLocale: 'it-IT' },
    { label: 'Italiano', localeId: 'it-IT', dateFormat: 'dd/MM/yyy', currency: 'EUR', numberLocale: 'it-IT' },
  ];

  constructor(private translate: TranslateService, private storage: StorageService, private primeNGConfig: PrimeNGConfig) {}

  getTranslation(path: string, replacer?: object): string {
    return this.translate.instant(path, replacer) as string;
  }

  setDefaultLanguage(lang: Language): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.currentLanguage.next(lang);
    this.storage.setOnStorage(StorageKey.CurrentLanguage, lang);
    this.translate.get('PRIMENG').subscribe((res: Translation) => this.primeNGConfig.setTranslation(res));
  }
}
