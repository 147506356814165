import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

// Environments
import { environment } from '../../../environments/environment';

// Types
import { AuthUser } from '../../shared/types/user';
import { PResponse } from '../../shared/types/presponse';

// Enums
import { StorageKey } from '../../shared/enums/storage';

// Services
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);

  constructor(private http: HttpClient, private storage: StorageService) {}

  recoverSession(): void {
    const user = this.storage.getFromStorage<AuthUser>(StorageKey.CurrentUser);
    this.user.next(user);
  }

  login(email: string, password: string): Observable<AuthUser> {
    const url = environment.url + '/api/AuthLogin';
    return this.http.post<PResponse<AuthUser>>(url, { email, password }).pipe(
      map((response) => {
        return this.setUser(response.data);
      })
    );
  }

  logout(): void {
    this.setUser(null);
  }

  refreshToken(): Observable<AuthUser> {
    const url = environment.url + '/api/AuthRefreshToken';

    const { token } = this.user.getValue().refreshToken;
    const headers = new HttpHeaders({
      AutRefreshToken: token,
    });

    return this.http.post<PResponse<AuthUser>>(url, {}, { headers }).pipe(
      map((response) => {
        return this.setUser(response.data);
      })
    );
  }

  forgotPassword(email: string, urlToSend: string): Observable<PResponse> {
    const url = environment.url + '/api/AuthForgotPassword';
    return this.http.post<PResponse>(url, { Email: email, Url: urlToSend });
  }

  resetPassword(token: string, newPassword: string): Observable<PResponse> {
    const url = environment.url + '/api/AuthResetPassword';
    return this.http.post<PResponse>(url, { ChangePwdToken: token, NewPassword: newPassword });
  }

  private setUser(user: AuthUser): AuthUser {
    this.storage.setOnStorage(StorageKey.CurrentUser, user);
    this.user.next(user);
    return user;
  }
}
