import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocompleteDirective } from './autocomplete/autocomplete.directive';

const DIRECTIVES = [AutocompleteDirective];

@NgModule({
  declarations: DIRECTIVES,
  imports: [CommonModule],
  exports: DIRECTIVES,
})
export class DirectivesModule {}
