import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { AuthenticationService } from '../authentication/authentication.service';

const AUTH_APIS = ['AuthLogin', 'AuthRefreshToken', 'AuthForgotPassword', 'AuthResetPassword'];

export default function httpHeaders(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthenticationService);

  const url = request.url;
  const finded = !!AUTH_APIS.find((api) => url.includes(api));

  if (!finded) {
    const { token } = authService.user.getValue();
    const modifiedRequest = request.clone({
      headers: request.headers.append('AutToken', token),
    });

    return next(modifiedRequest);
  } else {
    return next(request);
  }
}
