import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { PrimeNGModule } from '../prime-ng.module';

// Modals
import { ExampleModalComponent } from './example-modal/example-modal.component';

const MODALS = [ExampleModalComponent];

@NgModule({
  declarations: MODALS,
  imports: [CommonModule, TranslateModule.forChild(), PrimeNGModule],
  exports: MODALS,
})
export class ModalsModule {}
