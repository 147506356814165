import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pfa-progress-bar',
  templateUrl: './pfa-progress-bar.component.html',
  styleUrls: ['./pfa-progress-bar.component.scss'],
})
export class PfaProgressBarComponent {
  @Input() title: string = '';
  @Input() value: number = 0;
  @Input() note: string = '';

  constructor() {}

  protected get colorClass(): string {
    if (this.value <= 20) {
      return 'danger';
    } else if (this.value <= 60) {
      return 'warning';
    } else {
      return 'success';
    }
  }
}
