<div class="flex flex-column align-items-center gap-5 w-full">
  <p-fileUpload
    #fileuploader
    [disabled]="disabled || uploading"
    class="min-w-full"
    styleClass="border-2 border-dashed border-blue-800 border-round"
    [showUploadButton]="false"
    [showCancelButton]="false"
    chooseStyleClass="hidden"
    [auto]="true"
    [accept]="accept"
    [fileLimit]="limitSize"
    [multiple]="multiple"
    [url]="url"
    [name]="name"
    [customUpload]="customUpload"
    (onBeforeUpload)="uploading = true"
    (uploadHandler)="handleUpload($event)"
    (onUpload)="filesUploaded($event)"
    (onError)="handleError($event)"
  >
    <ng-template pTemplate="content">
      <div
        #placeholder
        id="placeholder"
        *ngIf="!fileuploader.files.length"
        class="w-full flex flex-column align-items-center gap-3 py-8"
        [class.bg-blue-200]="hasFilesOver"
      >
        <i class="pi pi-cloud-upload text-8xl text-blue-500"></i>
        <h1 class="text-3xl text-blue-500 m-0 text-center">{{ title }}</h1>
        <button
          *ngIf="!buttonIsExternal"
          type="button"
          class="w-min px-8"
          (click)="fileuploader.choose()"
          pButton
          [label]="browseLabel"
        ></button>
        <p class="m-0 font-bold text-blue-500 text-center">{{ subtitle }}</p>
      </div>
    </ng-template>
    <ng-template let-file pTemplate="file">
      <div class="grid grid-nogutter p-3">
        <div class="col">
          <div class="flex flex-column">
            <p class="font-bold m-0">{{ file.name }}</p>
            <span class="font-light">{{ getFileSize(file.size) }} kb</span>
          </div>
        </div>
        <div *ngIf="editable" class="col-fixed flex align-items-center">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-sm p-button-rounded p-button-raised p-button-text border-1 surface-border p-button-secondary bg-gray-100"
            (click)="removeFile(file)"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
  <ng-container *ngIf="buttonIsExternal">
    <span translate>COMMON.OR</span>
    <button
      type="button"
      class="w-min px-8"
      [disabled]="disabled || uploading"
      (click)="fileuploader.choose()"
      pButton
      [label]="browseLabel"
    ></button>
  </ng-container>
</div>

<p-toast position="top-right"></p-toast>
