import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Types
import {
  GetYearEndAwardParams,
  GetYearEndAwardResponse,
  GetYearEndAwardsParams,
  GetYearEndAwardsResponse,
  SetYearEndAwardsParams,
  SetYearEndAwardsResponse,
} from '../../../shared/types/year-end-award';

// Environment
import { environment } from '../../../../environments/environment';

@Injectable()
export class YearEndAwardsService {
  constructor(private http: HttpClient) {}

  getYearEndAward(params: GetYearEndAwardParams): Observable<GetYearEndAwardResponse> {
    const url = environment.url + '/api/GetYearEndAward';
    return this.http.post<GetYearEndAwardResponse>(url, params);
  }

  getYearEndAwards(params: GetYearEndAwardsParams): Observable<GetYearEndAwardsResponse> {
    const url = environment.url + '/api/GetYearEndAwards';
    return this.http.post<GetYearEndAwardsResponse>(url, params);
  }

  setYearEndAward(params: SetYearEndAwardsParams): Observable<SetYearEndAwardsResponse> {
    const url = environment.url + '/api/SetYearEndAward';
    return this.http.post<SetYearEndAwardsResponse>(url, params);
  }
}
