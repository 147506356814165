import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxEchartsModule } from 'ngx-echarts';

import { PrimeNGModule } from '../prime-ng.module';

import { PageComponent } from './page/page.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { BlockUIComponent } from './block-ui/block-ui.component';
import { ChartComponent } from './chart/chart.component';
import { PfaProgressBarComponent } from './pfa-progress-bar/pfa-progress-bar.component';

const COMPONENTS = [PageComponent, FileUploaderComponent, ChartComponent, BlockUIComponent, PfaProgressBarComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, TranslateModule.forChild(), PrimeNGModule, NgxEchartsModule.forChild()],
  exports: COMPONENTS,
})
export class ComponentsModule {}
