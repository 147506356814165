<div class="flex justify-content-between align-items-center w-full p-5">
  <div class="flex flex-row">
    <div>
      <h6 class="my-0 text-base font-normal">{{ subtitle }}</h6>
      <h1 class="text-4xl font-bold mt-0 mb-2">{{ title }}</h1>
      <span class="title-underline"></span>
    </div>
    <div *ngIf="orderStatus">
      <button *ngIf="orderStatus === 'Shipped'" pButton pRipple type="button" [label]="orderStatus" class="p-button-success ml-5"></button>
      <button *ngIf="orderStatus === 'Sent'" pButton pRipple type="button" [label]="orderStatus" class="p-button-warning ml-5"></button>
    </div>
  </div>

  <div class="flex align-items-center gap-4">
    <ul class="list-none gap-5 flex align-items-center">
      <li *ngFor="let page of availablePages">
        <a
          class="router-link font-bold no-underline hover:text-blue-300"
          [routerLink]="page.path"
          routerLinkActive="text-blue-500"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ page.name | translate }}
        </a>
      </li>
      <li>
        <a class="router-link font-bold no-underline cursor-pointer hover:text-blue-300" (click)="logout()" translate>HEADER.LOG_OUT</a>
      </li>
    </ul>

    <!-- <div class="flex gap-1">
      <button pButton type="button" icon="pi pi-search" class="p-button-secondary p-button-rounded p-button-text"></button>
      <button pButton type="button" icon="pi pi-bell" class="p-button-secondary p-button-rounded p-button-text"></button>
    </div> -->

    <p-divider layout="vertical"></p-divider>

    <div class="flex align-items-center gap-3">
      <div class="p-1 border-1 border-300 border-circle flex justify-content-center align-items-center">
        <p-avatar shape="circle" size="xlarge" styleClass="bg-blue-500 text-white" [label]="userFirstLetters"></p-avatar>
      </div>
      <div class="text-left">
        <p class="font-bold text-sm my-0">{{ userFullName }}</p>
        <p class="text-sm my-0">{{ companyName }}</p>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog styleClass="w-3 min-w-max" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-toast position="top-right"></p-toast>
