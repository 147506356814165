import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'example-modal',
  templateUrl: './example-modal.component.html',
  styleUrls: ['./example-modal.component.scss'],
})
export class ExampleModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
