import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Types
import {
  GetOfferParams,
  GetOfferResponse,
  GetOfferRowResponse,
  OfferCreationByFileUploadParams,
  OfferCreationByFileUploadResponse,
  SetOfferRowParams,
  SetOfferRowResponse,
} from '../../../shared/types/product';

// Environment
import { environment } from '../../../../environments/environment';

@Injectable()
export class OffersService {
  constructor(private http: HttpClient) {}

  getOffer(params: GetOfferParams): Observable<GetOfferResponse> {
    const url = environment.url + '/api/GetOffer';
    return this.http.post<GetOfferResponse>(url, params);
  }

  getOfferRow(idofferrow: number): Observable<GetOfferRowResponse> {
    const url = environment.url + '/api/GetOfferRow';
    return this.http.post<GetOfferRowResponse>(url, { Idofferrow: idofferrow });
  }

  setOfferRow(params: SetOfferRowParams): Observable<SetOfferRowResponse> {
    const url = environment.url + '/api/SetOfferRow';
    return this.http.post<SetOfferRowResponse>(url, params);
  }

  getOfferTemplate(): Observable<ArrayBuffer> {
    const url = environment.url + '/api/GetOfferTemplate';
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  offerCreationByFileUpload(params: OfferCreationByFileUploadParams): Observable<OfferCreationByFileUploadResponse> {
    const url = environment.url + '/api/OfferCreationByFileUpload';
    const [formData, { companiesList, file }] = [new FormData(), params];
    formData.append('companiesList', companiesList.join(','));
    formData.append('file', file);
    return this.http.post<OfferCreationByFileUploadResponse>(url, formData);
  }
}
