import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Types
import {
  GetProductDetailParams,
  GetProductDetailResponse,
  GetProductsParams,
  GetProductListResponse,
  GetProductInfoResponse,
  GetProductHistoryParams,
  GetProductHistoryResponse,
  GetAiProducts,
  GetAiProductListResponse,
} from '../../../shared/types/product';

// Environment
import { environment } from '../../../../environments/environment';

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}

  getProducts(params: GetProductsParams): Observable<GetProductListResponse> {
    const url = environment.url + '/api/GetProducts';
    return this.http.post<GetProductListResponse>(url, params);
  }

  getAiProducts(params: GetAiProducts): Observable<GetAiProductListResponse> {
    const url = environment.url + '/api/GetAiProducts';
    return this.http.post<GetAiProductListResponse>(url, params);
  }

  getProduct(params: GetProductDetailParams): Observable<GetProductDetailResponse> {
    const url = environment.url + '/api/GetProduct';
    return this.http.post<GetProductDetailResponse>(url, params);
  }

  getProductInfo(code: string): Observable<GetProductInfoResponse> {
    const url = environment.url + '/api/GetProductInfo';
    return this.http.post<GetProductInfoResponse>(url, { Code: code });
  }

  getProductHistory(params: GetProductHistoryParams): Observable<GetProductHistoryResponse> {
    const url = environment.url + '/api/GetProductHistory';
    return this.http.post<GetProductHistoryResponse>(url, params);
  }
}
