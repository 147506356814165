import { AfterViewInit, Directive, Input } from '@angular/core';
import { Password } from 'primeng/password';

@Directive({
  selector: '[appAutocomplete]',
})
export class AutocompleteDirective implements AfterViewInit {
  @Input() appAutocomplete: 'current-password' | 'new-password' = 'current-password';
  constructor(private pPassword: Password) {}

  ngAfterViewInit(): void {
    const input = this.pPassword.input.nativeElement as HTMLInputElement;
    input.autocomplete = this.appAutocomplete;
  }
}
