import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GetMonitoredProductsResponse, MonitoredProduct, SetMonitoredProductResponse } from 'src/app/shared/types/availabilities';
// Environment
import { environment } from '../../../../environments/environment';

// Types
import { GetCompaniesListRespone, GetCompanyTypesResponse } from '../../../shared/types/company';
import { UserRoles } from '../../../shared/types/user';
import { GetCitiesListRespone } from '../../../shared/types/cities';

@Injectable({
  providedIn: 'root',
})
export class AvailabilitiesService {
  constructor(private http: HttpClient) {}

  setMonitoredProduct(monitoredProduct: MonitoredProduct): Observable<SetMonitoredProductResponse> {
    const url = environment.url + '/api/AddMonitoredProduct';
    return this.http.post<SetMonitoredProductResponse>(url, monitoredProduct);
  }

  getMonitoredProducts(): Observable<GetMonitoredProductsResponse> {
    const url = environment.url + '/api/GetMonitoredProducts';
    return this.http.get<GetMonitoredProductsResponse>(url);
  }

  getCompanyTypes(): Observable<GetCompanyTypesResponse> {
    const url = environment.url + '/api/GetCompanyTypes';
    return this.http.post<GetCompanyTypesResponse>(url, {});
  }

  getCities(): Observable<GetCitiesListRespone> {
    const url = environment.url + '/api/GetCities';
    return this.http.post<GetCitiesListRespone>(url, {});
  }
}
