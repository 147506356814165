import { Injectable } from '@angular/core';

// Enums
import { StorageKey } from '../../shared/enums/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setOnStorage(key: StorageKey, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getFromStorage<T>(key: StorageKey): T {
    const item = localStorage.getItem(key);
    return JSON.parse(item) as T;
  }

  clearStorage(): void {
    localStorage.clear();
  }
}
