import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Environment
import { environment } from '../../../../environments/environment';

// Types
import {
  DeleteUserInput,
  DeleteUserResponse,
  UpdateUserInput,
  UpdateUserResponse,
  UserDetailFilters,
  UserDetailResponse,
  UsersListFilters,
  UsersListResponse,
} from '../../../shared/types/user';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {}

  getUsers(params: UsersListFilters): Observable<UsersListResponse> {
    const url = environment.url + '/api/GetUsers';
    return this.http.post<UsersListResponse>(url, params);
  }

  getUser(params: UserDetailFilters): Observable<UserDetailResponse> {
    const url = environment.url + '/api/GetUser';
    return this.http.post<UserDetailResponse>(url, params);
  }

  setUser(params: UpdateUserInput): Observable<UpdateUserResponse> {
    const url = environment.url + '/api/SetUser';
    return this.http.post<UpdateUserResponse>(url, params);
  }

  deleteUser(params: DeleteUserInput): Observable<DeleteUserResponse> {
    const url = environment.url + '/api/DeleteUser';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return this.http.delete<DeleteUserResponse>(url, { body: params });
  }
}
