import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as AngularDatePipe } from '@angular/common';

// Services
import { TranslationService } from '../../../core/services/translation.service';

@Pipe({
  name: 'appDate',
})
export class DatePipe extends AngularDatePipe implements PipeTransform {
  constructor(private translate: TranslationService) {
    super(translate.currentLanguage.getValue());
  }

  override transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): null;
  override transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): string {
    const dateLocale = this.translate.currentLanguage.getValue();
    //  const format = this.translate.languages.find((l) => l.localeId === locale)?.dateFormat ;
    const dateFormat = format || 'dd/MM/yyyy HH:mm';
    return super.transform(value, dateFormat, timezone, dateLocale);
  }
}
