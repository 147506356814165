import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Environment
import { environment } from '../../../../environments/environment';

// Types
import { OrderResponse, OrdersListFilters, OrdersListResponse } from '../../../shared/types/order';
import { OrderConfirmationParams, OrderCreationParams, OrderCreationResponse } from '../../../shared/types/order-creation';

@Injectable()
export class OrdersService {
  constructor(private http: HttpClient) {}

  getOrders(params: OrdersListFilters): Observable<OrdersListResponse> {
    const url = environment.url + '/api/GetOrders';
    return this.http.post<OrdersListResponse>(url, params);
  }

  getOrder(iddocument: number): Observable<OrderResponse> {
    const url = environment.url + '/api/GetOrder';
    return this.http.post<OrderResponse>(url, { Iddocument: iddocument });
  }

  confirmOrder(params: OrderConfirmationParams): Observable<void> {
    const url = environment.url + '/api/ConfirmOrder';
    return this.http.post<void>(url, params);
  }

  getOrderTemplate(): Observable<ArrayBuffer> {
    const url = environment.url + '/api/GetOrderTemplate';
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  orderCreationByFileUpload(params: OrderCreationParams): Observable<OrderCreationResponse> {
    const url = environment.url + '/api/OrderCreationByFileUpload';
    const formData = new FormData();
    Object.keys(params).forEach((key) => formData.append(key, params[key as keyof typeof params] as string | Blob));
    return this.http.post<OrderCreationResponse>(url, formData);
  }
}
