import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { SharedModule } from '../shared/shared.module';

// Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// HTTP Services
import { ApiService } from './http/api.service';
import { OrdersService } from './http/orders/orders.service';
import { UsersService } from './http/users/users.service';
import { CompaniesService } from './http/companies/companies.service';
import { OffersService } from './http/offers/offers.service';
import { ProductsService } from './http/products/products.service';
import { YearEndAwardsService } from './http/year-end-awards/year-end-awards.service';
import { StatisticsService } from './http/statistics/statistics.service';

const COMPONENTS = [HeaderComponent, FooterComponent];
const HTTP_SERVICES = [
  OrdersService,
  UsersService,
  CompaniesService,
  OffersService,
  ProductsService,
  YearEndAwardsService,
  StatisticsService,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, TranslateModule.forChild(), SharedModule],
  exports: COMPONENTS,
  providers: [ApiService, ...HTTP_SERVICES],
})
export class CoreModule {}
