import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

// PrimeNG
import { FileUpload } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';

// Types
import { EmitEvent } from '../../types/emit-types';

// Services
import { TranslationService } from '../../../core/services/translation.service';

type UploadError = { files: File[]; error: HttpErrorResponse };

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements AfterViewInit {
  @Input() multiple: boolean = false;
  @Input() limitSize: number = null;
  @Input() accept: string = null;
  @Input() buttonIsExternal: boolean = true;
  @Input() title: string = null;
  @Input() subtitle: string = '';
  @Input() browseLabel: string = '';
  @Input() url: string = null;
  @Input() name: string = null;
  @Input() customUpload: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() editable?: boolean = false;
  @Output() uploaded: EventEmitter<unknown> = new EventEmitter<unknown>(false);
  @Output() uploadHandler: EventEmitter<EmitEvent<FileUpload>> = new EventEmitter<EmitEvent<FileUpload>>(false);

  @ViewChild('fileuploader') fileUploader: FileUpload;
  @ViewChild('placeholder') placeholder: ElementRef<HTMLDivElement>;

  protected hasFilesOver: boolean = false;
  protected uploading: boolean = false;

  constructor(private messageService: MessageService, private translationService: TranslationService) {}

  ngAfterViewInit(): void {
    this.placeholder.nativeElement.ondragenter = () => (this.hasFilesOver = true);
    this.placeholder.nativeElement.ondragleave = () => (this.hasFilesOver = false);
    this.placeholder.nativeElement.ondrop = () => (this.hasFilesOver = false);
  }

  protected removeFile(file: File): void {
    const index = this.fileUploader.files.indexOf(file);
    this.fileUploader.files.splice(index, 1);
  }

  protected getFileSize(size: number): string {
    return Math.round(size / 1024).toFixed(2);
  }

  protected filesUploaded(event: { originalEvent: HttpResponse<unknown> }): void {
    this.uploaded.emit(event.originalEvent.body);
  }

  protected handleError({ error }: UploadError): void {
    this.uploading = false;
    this.fileUploader.clear();
    this.messageService.add({
      summary: this.translationService.getTranslation('COMMON.ERROR'),
      detail: error.message,
      severity: 'error',
      life: 2000,
    });
  }

  protected handleUpload(event: Event): void {
    this.uploading = false;
    this.uploadHandler.emit({ target: this.fileUploader, event });
  }
}
