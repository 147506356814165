import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe as AngularDecimalPipe } from '@angular/common';

// Services
import { TranslationService } from '../../../core/services/translation.service';

@Pipe({
  name: 'appNumber',
})
export class DecimalPipe extends AngularDecimalPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {
    super(translationService.currentLanguage.getValue());
  }

  override transform(value: null, digitsInfo?: string, locale?: string): null;
  override transform(value: string | number, digitsInfo?: string, locale?: string): string {
    const numberLocale = locale || this.translationService.currentLanguage.getValue();
    return super.transform(value, digitsInfo, numberLocale);
  }
}
