import { Component, Input } from '@angular/core';

// ECharts
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective } from 'ngx-echarts';

// Services
import { TranslationService } from '../../../core/services/translation.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  @Input() options: EChartsOption;
  @Input() hasNoData: boolean = false;
  @Input() loading: boolean = false;

  protected readonly noDataLabel: EChartsOption = {
    graphic: {
      elements: [
        {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: this.translationService.getTranslation('COMMON.NO_DATA_AVAILABLE'),
            fontSize: 16,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            textAlign: 'center',
            fontFamily: 'Open Sans',
          },
        },
      ],
    },
  };

  protected readonly initOpts: NgxEchartsDirective['initOpts'] = {
    renderer: 'svg',
    locale: this.translationService.currentLanguage.getValue(),
  };

  constructor(private translationService: TranslationService) {}
}
