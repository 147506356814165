<p-blockUI [blocked]="blocked" styleClass="z-5">
  <div #customContent>
    <ng-content *ngIf="showLoader"></ng-content>
  </div>

  <p-progressSpinner
    *ngIf="showLoader && !customContent.children.length"
    styleClass="custom-spinner"
    animationDuration=".5s"
    strokeWidth="1px"
  ></p-progressSpinner>
</p-blockUI>
