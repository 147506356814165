import { NgModule } from '@angular/core';

import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { ModalsModule } from './modals/modals.module';
import { PipesModule } from './pipes/pipes.module';
import { PrimeNGModule } from './prime-ng.module';

@NgModule({
  exports: [ComponentsModule, DirectivesModule, ModalsModule, PipesModule, PrimeNGModule],
})
export class SharedModule {}
