import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

// Services
import { AuthenticationService } from '../authentication/authentication.service';

// Enums
import { Roles } from '../../shared/enums/roles';

export function isAuthenticatedGuard(): boolean | UrlTree {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const isAuthenticated = authService.user.getValue() !== null;

  if (isAuthenticated) {
    return true;
  } else {
    return router.createUrlTree(['/sign']);
  }
}

export function isNotAuthenticatedGuard(): boolean | UrlTree {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const isAuthenticated = authService.user.getValue() !== null;
  const userRoles = authService.user.getValue()?.masterData?.roles;

  if (!isAuthenticated) {
    return true;
  } else {
    return router.createUrlTree([userRoles.includes(Roles.Hospital) ? '/predictions' : '/companies']);
  }
}

export function checkRoles(...roles: Roles[]): () => boolean | UrlTree {
  return (): boolean | UrlTree => {
    const authService = inject(AuthenticationService);
    const user = authService.user.getValue();
    const router = inject(Router);

    if (!user) {
      return false;
    } else {
      const userRoles = user.masterData.roles;
      const roleFound = roles.find((role) => userRoles.includes(role));
      return !!roleFound || router.createUrlTree([userRoles.includes(Roles.Hospital) ? '/predictions' : '/companies']);
    }
  };
}
