<div class="grid grid-nogutter w-full bg-white shadow-5 p-3 align-items-center row-gap-5">
  <div class="col-6 lg:col-3"><img src="../assets/logos/logo-text-020d19.png" class="w-6" alt="" /></div>
  <div [innerHTML]="'FOOTER.CONTENT' | translate" class="col-6 lg:col-6 text-xs text-center"></div>
  <div class="col-12 lg:col-3 flex justify-content-center lg:justify-content-end align-items-center column-gap-4">
    <ng-container *ngFor="let logo of logos">
      <div class="logo">
        <img [src]="'../assets/logos/' + logo" />
      </div>
    </ng-container>
  </div>
</div>
