import { Injectable } from '@angular/core';

import { AvailabilitiesService } from './availabilities/availabilities.service';
import { OrdersService } from './orders/orders.service';
import { UsersService } from './users/users.service';
import { CompaniesService } from './companies/companies.service';
import { OffersService } from './offers/offers.service';
import { ProductsService } from './products/products.service';
import { YearEndAwardsService } from './year-end-awards/year-end-awards.service';
import { StatisticsService } from './statistics/statistics.service';

@Injectable()
export class ApiService {
  constructor(
    public orders: OrdersService,
    public users: UsersService,
    public companies: CompaniesService,
    public offers: OffersService,
    public product: ProductsService,
    public availabilities: AvailabilitiesService,
    public yearEndAwards: YearEndAwardsService,
    public statistics: StatisticsService
  ) {}
}
