import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  protected readonly logos: string[] = [
    'unione_europea_logo.png',
    'ministero_sviluppo_economico_logo.png',
    'regione_puglia_logo.png',
    'fesr_fse_logo.png',
  ];

  constructor() {}
}
