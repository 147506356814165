import { HttpEvent, HttpRequest, HttpErrorResponse, HttpHandlerFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// Types
import { PResponse } from '../../shared/types/presponse';

// Services
import { AuthenticationService } from '../authentication/authentication.service';
import { TranslationService } from '../services/translation.service';

function refreshToken(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthenticationService,
  translationService: TranslationService,
  router: Router
) {
  const logout = (error: unknown): Observable<never> => {
    authService.logout();
    void router.navigate(['/sign'], { replaceUrl: true });
    return throwError(() => error);
  };

  const dueDate = authService.user.getValue().refreshToken.dueDate;
  const expData = new Date(dueDate);

  if (differenceInSeconds(new Date('2024-01-01'), expData) < 0) {
    return authService.refreshToken().pipe(
      switchMap((user) => {
        const newRequest = request.clone({
          headers: request.headers.append('AutToken', user.token),
        });
        return next(newRequest);
      }),
      catchError(logout)
    );
  } else {
    return logout({ error: translationService.getTranslation('ERRORS.TOKEN_EXPIRED') });
  }
}

export default function errorHandler(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthenticationService);
  const translationService = inject(TranslationService);
  const router = inject(Router);

  if (request.method === 'GET') {
    return next(request);
  }

  return next(request).pipe(
    switchMap((event) => {
      if (event instanceof HttpResponse) {
        const response = event.body as PResponse<unknown>;
        if (response.code !== 200 && response.code !== 201) {
          const error = new HttpErrorResponse({
            headers: event.headers,
            status: response.code,
            error: response.message,
            statusText: 'INTERNAL_ERROR',
            url: event.url,
          });
          return throwError(() => error);
        }
      }
      return of(event);
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        return refreshToken(request, next, authService, translationService, router);
      }

      return throwError(() => error);
    })
  );
}
