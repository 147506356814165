import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DatePipe } from './date/date.pipe';
import { CurrencyPipe } from './currency/currency.pipe';
import { DecimalPipe } from './decimal/decimal.pipe';

const PIPES = [DatePipe, CurrencyPipe, DecimalPipe];

@NgModule({
  declarations: PIPES,
  imports: [CommonModule],
  exports: PIPES,
})
export class PipesModule {}
